import React, { useRef, useEffect, useState } from "react";
import axios from "axios";

export default function Gallery({ onNumImagesChange }) {
  const [imgUrls, setImgUrls] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [imagesPerPage, setImagesPerPage] = useState(1);

  const imageBox = useRef(null);

  const config = {
    headers: {
      Authorization: "Bearer 6d91366b1570f57e18431d3581ed78afe6bb64d7fde38be4b3df3f08fd4b8d83184344a85b0a49925c3a957d58e9764d8ae4bdc2fcdc5952fea7948ba3eaf724488648a354dfd7ab6abf1c1357ece89dce648554c33169c705e5e0d4da2363cb2b7ebde31dc9b8e2a24c61810f109a57c0b59415e5ddb95ee2ee568475c5abd8",
    },
  };

  let fetchedUrls = [];

  useEffect(() => {
    axios
      .get("https://api.neecist.org/api/images-neecathons?populate=Images", config)
      .then((response) => {
        response.data.data.forEach((element) => {
          
          const imgUrl = "https://api.neecist.org" + element.attributes.Images.data[0].attributes.url;
  
          fetchedUrls.push(imgUrl);
        });
        setImgUrls(fetchedUrls.reverse()); // Reverse if needed
        setImagesPerPage(fetchedUrls.length);
        if (onNumImagesChange) {
          onNumImagesChange(fetchedUrls.length);
        }

      });
  }, []);

  useEffect(() => {
    // Calculate the number of images that can fit within 80vw
    const updateImagesPerPage = () => {
      const containerWidth = Math.max(window.innerWidth * 0.80, 600); // max width 80vw or 600px
      const imageWidth = 150; // assumed width per image
      const numImages = Math.floor(containerWidth / imageWidth);
      setImagesPerPage(numImages);
      if (onNumImagesChange) {
        onNumImagesChange(numImages*imageWidth); // Pass the value to the parent component
      }
    };

    window.addEventListener('resize', updateImagesPerPage);
    updateImagesPerPage();

    return () => {
      window.removeEventListener('resize', updateImagesPerPage);
    };
  }, [onNumImagesChange]);

  const goToNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % imgUrls.length);
  };

  const goToPrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + imgUrls.length) % imgUrls.length);
  };

  return (
    <div className="image-box" ref={imageBox} style={{ position: "relative" }}>
      {imgUrls.length > 0 ? (
        <>
          <button onClick={goToPrev} className="arrow left-arrow">❮</button>
          <div className="images-wrapper">
            {imgUrls.slice(currentIndex, currentIndex + imagesPerPage).map((url, idx) => (
              <img key={idx} src={url} alt={`Slide ${currentIndex + idx}`} className="gallery-image" />
            ))}
          </div>
          <button onClick={goToNext} className="arrow right-arrow">❯</button>
        </>
      ) : (
        <p>Loading...</p>
      )}
      <style>{`
        .arrow {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          background-color: rgba(0, 0, 0, 0.5);
          color: white;
          border: none;
          cursor: pointer;
          padding: 10px;
          z-index: 1;
        }
        .left-arrow {
          left: 10px;
        }
        .right-arrow {
          right: 10px;
        }
      `}</style>
    </div>
  );
}
