import React, { useState, useEffect } from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  ListGroup,
  Form
} from 'react-bootstrap';
import ProfileServices from "../core/ProfileServices";
import { Navigate, useLocation } from "react-router-dom";
import supabaseClient from "../utils/supabaseClient";
import axios from "axios";
import loading_icon from "../images/loading.svg";


function Register() {
  let location = useLocation();
  const [isUserInPersonsTable, setIsUserInPersonsTable] = useState(false);
  const [logged, setLogged] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const [teams, setTeams] = useState([]);

  const [formData, setFormData] = useState({
    projectName: '',
    projectTitle: '',
    projectDescription: '',
  });

  const checkUserInPersonsTable = async (email) => {
			const { data, error } = await supabaseClient
				.from('Persons')
				.select('Email')
				.eq('Email', email);

			if (error) {
				console.error('Error checking user in Persons table:', error);
				return false;
			}

			if (data.length === 0) {
				console.log('User not found in Persons table');
				return false;
			}else{
				console.log('User found in Persons table');
				return true;
			}
		};

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const [selectedTeam, setSelectedTeam] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
    // Handle team creation logic here
  };

  const handleOkClick = async () => {
    try {
      // Perform the database update logic here
      console.log(`Selected team: ${selectedTeam.NAME}`);
      //TODO ; UUPDATE PERSONS TABLE AND REDIRECT USER NOT REDIRECTING!
      const email = supabaseClient.auth.user().email;
      const name = supabaseClient.auth.user().user_metadata.full_name;
      const user_id = supabaseClient.auth.user().id;
      const team_id = selectedTeam.IDTEAM;


      await axios.post("https://neecathonbackend.neecist.org/joinTeam", { Name: name, Email: email, IDTEAM: team_id, user_id: user_id, token: supabaseClient.auth.currentSession.access_token })

      window.location.href = "/home";
    } catch (error) {
      console.error("Error updating database:", error.message);
      alert("An error occurred while updating the database. Please try again.");
    }
  };

  const createTeam = async () => {
    try {
      const email = supabaseClient.auth.user().email;
      const name = supabaseClient.auth.user().user_metadata.full_name;
      const user_id = supabaseClient.auth.user().id;
      const { projectName, projectTitle, projectDescription } = formData;
      console.log("Creating team with: ", formData); // Check the payload structure here

      await axios.post("https://neecathonbackend.neecist.org/createTeam", { Name: name, Email: email, user_id: user_id, ProjectName: projectName, ProjectTitle: projectTitle, ProjectDescription: projectDescription, token: supabaseClient.auth.currentSession.access_token })

      window.location.href = "/home";
    } catch (error) {
      console.error("Error updating database:", error.message);
      alert("An error occurred while updating the database. Please try again.");
    }


  };


  useEffect(() => {

    const checkUser = async () => {
      setLoading(true);
      const user = supabaseClient.auth.user();

      if (user === null) {
        setLogged(false);
        setLoading(false);
        return;
      }
      setLogged(true);
      const { email } = user;
      // Wait for checkUserInPersonsTable to resolve
      const UserInPersonsTable = await checkUserInPersonsTable(email);
      setIsUserInPersonsTable(UserInPersonsTable);
      setLoading(false);
    };
    ProfileServices.getTeams(setTeams);
    setTimeout(() => {
          checkUser();
      }, 1000);
  
  }, []);

  if (logged === false) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  if(isUserInPersonsTable === true){
    return <Navigate to="/home" state={{ from: location }} />;
  }

  if(loading === true){
      return (
      <div style={{ display: "flex", width: "100vw", justifyContent: "center" }}>
        <img src={loading_icon} style={{ height: "43vh" }} alt="loading" />
      </div>
    );
  }else{
  return (

    <Container fluid className="team-page-container">
      <h1 className="logo-register-page">NEECATHON</h1>
      <Row className="justify-content-center align-items-center h-100">
        {/* Create New Team Section */}
        <Col md={5}>
          <Card className="p-4 create-team-section">
            <Card.Body>
              <Card.Title>Create New Team</Card.Title>
              <Form onSubmit={handleSubmit}>
                <Form.Group controlId="formTeamName">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="projectName"
                    value={formData.projectName}
                    onChange={handleInputChange}
                    placeholder="Enter team name"
                  />
                </Form.Group>
                <Form.Group controlId="formProjectTitle" className="mt-3">
                  <Form.Label>Project Title</Form.Label>
                  <Form.Control
                    type="text"
                    name="projectTitle"
                    value={formData.projectTitle}
                    onChange={handleInputChange}
                    placeholder="Enter project title"
                  />
                </Form.Group>
                <Form.Group controlId="formProjectDescription" className="mt-3">
                  <Form.Label>Project Description</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    name="projectDescription"
                    value={formData.projectDescription}
                    onChange={handleInputChange}
                    placeholder="Enter project description"
                  />
                </Form.Group>
                <button onClick={createTeam} type="submit" className="button1">
                  Create Team
                </button>
              </Form>
            </Card.Body>
          </Card>
        </Col>

        {/* Join a Team Section */}
        <Col md={5}>
          <Card className="p-4 join-team-section">
            <Card.Body>
              <Card.Title>Join a Team</Card.Title>
              <ListGroup className="scrollable-list">
                {teams.slice(2).map((team) => ( /*remover as equipas de devs*/
                  <ListGroup.Item
                    key={team.IDTEAM}
                    className="team-item"
                    onClick={() => setSelectedTeam(team)}
                    active={selectedTeam?.IDTEAM === team.IDTEAM}
                  >
                    {team.NAME}

                    {/* Show the "OK" button only for the selected team */}
                    {selectedTeam?.IDTEAM === team.IDTEAM && (
                      <Button
                        variant="success"
                        className="ml-2"
                        onClick={handleOkClick}
                      >
                        OK
                      </Button>
                    )}
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

}

export default Register;