import React, { useState } from "react";
import { Container, Card, Badge, ListGroup, Popover, OverlayTrigger } from "react-bootstrap";

import supabaseClient from "../utils/supabaseClient";
import { Navigate, useLocation } from "react-router-dom";

import ProfileServices from "../core/ProfileServices";
import LogsServices from "../core/LogServices";
import loading_icon from "../images/loading.svg";

function useForceUpdate() {
  const [value, setValue] = useState(0); // integer state
  return () => setValue((value) => value + 1); // update the state to force render
}

async function updateDB(id, state) {
  await supabaseClient.from("Logs").update({ DELIVERED: state }).eq("IDCT", id);
}


function StoreLogs() {
  const [logs, setLogs] = useState(undefined);
  const [teams, setTeams] = useState(undefined);
  const [loading, setLoading] = useState(true);

  const forceRefresh = useForceUpdate();

  function changeState(e) {
    const id = e.target.id;
    const state = e.target.checked;

    

    const updatedLogs = logs.map((log) =>
      log.IDCT == id ? { ...log, DELIVERED: state } : log
    );


    setLogs(updatedLogs);
     // Perform the database update (async, doesn't block UI)
  updateDB(id, state).catch((error) => {
    console.error("Database update failed:", error);
  });
}

  useState(() => {
    ProfileServices.getTeams(setTeams);
    LogsServices.getLogs().then((logs) => {
      
      setLogs(logs);
      setTimeout(function () {
        forceRefresh();
        setLoading(false);
      }, 5000);
    });
    
  }, []);

  let location = useLocation();
  if (supabaseClient.auth.user() === null) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  if (loading || teams === undefined) {
    return (
      <div style={{ display: "flex", width: "100vw", justifyContent: "center" }}>
        <img src={loading_icon} style={{ height: "43vh" }} alt="loading" />
      </div>
    );
  } else {
    console.log(logs);
    return (
      <Container fluid style={{ display: "flex", flexDirection: "row", alignItems: "center", height: "calc(100vh - 68px - 43px)" }}>
      <h1
        style={{
        writingMode: "tb-rl",
        color: "#f48ee5",
        transform: "rotate(180deg)",
        }}
      >
        Store Logs:
      </h1>
      <div style={{ display: "flex", flexDirection: "column", width: "100%", height: "80vh", overflow: "auto" }}>
        {logs.slice(0).reverse().map((log) => {
        
        return (
          <Card key={`card-${log.IDCT}`} className="logsCard" style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <div style={{ width: "30%" }}>
            {teams.map((team) => {
            if (team.IDTEAM === log.IDTEAM) {
              return (
              <div key={`div-${log.IDCT}`} style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                <h3 key={`h3-${log.IDCT}`}  style={{ color: "white" }}> {team.NAME}</h3>
                <p style={{ color: "white" }} >Timestamp: {log.LogTime}</p>
              </div>
              );
            }
            })}
          </div>
          <div style={{ flexGrow: "1" }}>
            {log.COMPONENTS.map((component) => {
            return (
              <ListGroup.Item key={`li-${log.IDCT}-${component.NAME}`} as="li" style={{ display: "flex" }}>
              <div key={`${component.NAME}-div`} className="ms-2 me-auto">
                <OverlayTrigger
                trigger={["hover", "focus"]}
                key={"bottom"}
                placement={"bottom"}
                overlay={
                  <Popover
                  key={`${component.NAME}-overlay`}
                  id={`popover-positioned-${"bottom"}`}
                  style={{ backgroundColor: " rgb(9, 1, 59)", background: "linear-gradient(180deg, rgba(9, 1, 59, 1) 51%, rgba(54, 36, 98, 1) 100%)" }}
                  >
                  <Popover.Header key={`${component.NAME}-overlay-header`} as="h3" style={{ backgroundColor: "rgba(9, 1, 59, 1)!important" }}>
                    Clica para aceder a datasheet
                  </Popover.Header>
                  <Popover.Body key={`${component.NAME}-overlay-body`}>
                    <img key={component.IMAGE} alt="" style={{ maxWidth: "100%" }} src={component.IMAGE}></img>
                  </Popover.Body>
                  </Popover>
                }
                >
                <a key={component.REFSHEET} href={component.REFSHEET} target="_blank" rel="noreferrer" style={{ color: "white" }}>
                  {component.NAME}
                </a>
                </OverlayTrigger>
              </div>
              <Badge key={component.NAME} variant="primary" pill>
                {component.QUANTITY}
              </Badge>
              </ListGroup.Item>
            );
            })}
          </div>
          <div style={{ display: "flex", flexDirection: "column", color: "white" }}>
            <label>Entregue?</label>
            <input
  id={log.IDCT}
  checked={log.DELIVERED}
  onChange={changeState}
  type="checkbox"
/>

          </div>
          </Card>
        );
        })}
      </div>
      </Container>
    );
  }
}
export default StoreLogs;
