import React, { useEffect, useState } from "react";
import { Container} from "react-bootstrap"
import supabaseClient from "../utils/supabaseClient";
import { Navigate } from "react-router-dom"

import horizontal_stripes from "../images/horizontal_stripes.svg";
import logo from "../images/neecathon_logo1.png";
import LoginFooter from '../components/LoginFooter';

function UserRedirect() {
	const [loading, setLoading] = useState(true);
	const [logged, setLogged] = useState(undefined);
	const [loader, setLoader] = useState(false);
	const [isUserInPersonsTable, setIsUserInPersonsTable] = useState(false);

	const checkUserInPersonsTable = async (email) => {
			const { data, error } = await supabaseClient
				.from('Persons')
				.select('Email')
				.eq('Email', email);

			if (error) {
				console.error('Error checking user in Persons table:', error);
				return false;
			}

			if (data.length === 0) {
				console.log('User not found in Persons table');
				return false;
			}else{
				console.log('User found in Persons table');
				return true;
			}
		};


	useEffect(() => {
        const checkUser = async () => {
            setLoading(true);
            const user = supabaseClient.auth.user();

            if (user === null) {
                setLogged(false);
                setLoading(false);
                return;
            }
			setLogged(true);
            const { email } = user;
			

            // Wait for checkUserInPersonsTable to resolve
            const UserInPersonsTable = await checkUserInPersonsTable(email);
			setIsUserInPersonsTable(UserInPersonsTable);
            setLoading(false);
        };

        setTimeout(() => {
            checkUser();
        }, 2000);
    }, []);


	if (loading) {
		return (
			<Container fluid className="p-0">
				<img className="horizontal_stripes" src={horizontal_stripes} alt="topimage" />
				<div className="container login-container">
					<h1 className="logo-login-page">NEECATHON</h1>
					<h3 style={{ color: 'white', fontSize: '48px' }}>A Carregar...</h3>
				</div>
				<LoginFooter />
			</Container>
		);
	}
	if (loader) {
		return (<Navigate to="/login" />)
	}
	if (logged) {
		
		
		if(isUserInPersonsTable === false){
			console.log('Redirecting to register page');
			return(<Navigate to	="/register" />)
		}else{
			console.log('Redirecting to home page');
			return (<Navigate to="/home" />)
		}

    	
		
	}
	else {
		setTimeout(function () {
			setLoader(true)
		}, 5000);
		return (
			<Container fluid className="p-0">
				<img className="horizontal_stripes" src={horizontal_stripes} alt="topimage" />
				<div className="container login-container">
					<img className="logo" src={logo} alt="Logo NEECathon" />
					<h3>Utilizador não encontrado a redirecionar para a pagina de login.</h3>
				</div>
				<LoginFooter />
			</Container>
		);
	}
}
export default UserRedirect;

