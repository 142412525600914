import "./index.css";
import React from "react";


export default function PageLayoutMobile(props) {
    return (
      <div style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
        <div className="page_container" style={{ flexGrow: "1", backgroundColor: "#020047", overflowY: "auto" }}>
          {props.children}
        </div>
      </div>
    );
  
}
