import { Container , Row, Col} from "react-bootstrap";



function Welcome() {

        return (
        <Container fluid className="p-0">
            <Row className="welcome-container">
                <Col xs={12}>
                    
                    <h1 className="logo-welcome-page responsive-icon">NEECATHON</h1>
                    
                    <div className="welcome-text" style={{ color: "white" }}>
                        <p>Bem-vindos à 6ª edição da NEECathon! 🎉</p>
                        <p>Estamos entusiasmados por estarem no evento connosco para mais um desafio repleto de inovação, colaboração e criatividade. Este será um evento inesquecível, e estamos aqui para garantir que tenham tudo o que precisam para criar ideias inovadoras e projetos incríveis!</p>
                        <p>Na <strong>Loja do NEEC</strong>, poderão requisitar os materiais necessários após realizar a compra no site oficial: <a href="https://neecathon.neecist.org">NEECathon</a>. Se precisarem de ferramentas específicas, não hesitem em ir buscar à Loja — estamos prontos para disponibilizar o que for necessário!</p>
                        <p>Se tiverem dúvidas durante a implementação de qualquer ideia ou projeto, não se preocupem!</p>
                        <ul>
                            <li>Podem solicitar o apoio de mentores diretamente através do site do NEEC e depois dirigir-se à Loja para formalizar o pedido.</li>
                            <li>Além disso, contamos com mentores especializados do <strong>IEEE</strong>, prontos para oferecer orientação técnica na área de eletrónica.</li>
                        </ul>
                        <p>Se precisarem de um espaço dedicado, podem requisitar salas através da Loja do NEEC. As salas funcionam com uma lista de espera e turnos de 1 hora, garantindo que todos tenham a oportunidade de trabalhar confortavelmente.</p>
                        <p>Os <strong>NEECos</strong> estão sempre à disposição para apoiar no que precisarem. Não se acanhem em pedir ajuda — estamos aqui para tornar esta experiência inesquecível para todos.</p>
                        <p>Divirtam-se, criem e inovem. Acima de tudo, aproveitem ao máximo esta oportunidade única!</p>
                    </div>
                </Col>
            </Row>
        </Container>
    );
	
}
export default Welcome;
